import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
// import WidgetBot, { API } from '@widgetbot/react-embed'

const cardStyle = {
  margin: 10, height: 100, cursor: 'pointer'
}

function HeroSection(props) {
  // const api = API;

  // const onAPI = (api) => {
  //   api.on('signIn', user => {
  //     console.log(`Signed in as ${user.name}`, user)
  //   })
  // }

  // function handleClick() {
  //   this.api.emit('sendMessage', `Hello world! from \`@widgetbot/react-embed\``)
  // }

  return (
    <Container maxWidth="lg">
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} 
      style={{ margin: 'auto', marginTop: '10px', textAlign: 'center'}}
      >
          {props.items?.map(item => (
              <Grid item xs={6} sm={4} md={3} lg={2} style={{ minHeight: 100 }} key={item.name} >
                <Card style={cardStyle} onClick={() => window.open(item.url, '_blank')}>
                <CardContent>
                  {item?.image && <img src={item.image} style={{ maxHeight: '50px', maxWidth: '-webkit-fill-available' }} />}
                    <Typography>{item.name}</Typography>
                  </CardContent>
              </Card>
              </Grid>
          ))}
      {/* <WidgetBot
          server="812041442461220985"
          channel="812042036479393792"
          username="Danny Clifford #9848"
          onAPI={onAPI}
          style= {{ width: '100%', height: '40vh' }}
        /> */}
      </Grid>

      {/* <iframe src="https://discord.com/widget?id=812041442461220985&channel=812042036479393792&theme=dark&username=812041065732898836" width="100%" height="500" allowtransparency="true" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe> */}
    </Container>
  );
}

export default HeroSection;
