import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import { requireAuth } from "./../util/auth";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Your landing page title here"
        subtitle="This landing page is perfect for showing off your awesome product and driving people to sign up for a paid plan."
        items={[
          {
            name: "Gmail",
            url: "https://mail.google.com",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/1024px-Gmail_icon_%282020%29.svg.png"
          },
          {
            name: "Website Leads",
            url: "https://docs.google.com/spreadsheets/d/13wTNGWDh3EQ4BZtC_uGYnmEdme3AWcbqxDojV5R0JbY/edit#gid=0",
            image: "https://res.cloudinary.com/dq5lw8oy1/image/upload/v1629990704/OpenGridLandingPage/OG_Logo_Black_Transparency_2x_wuj25t.png",
          },
          {
            name: "Github",
            url: "https://github.com/Open-Grid",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/GitHub_logo_2013.svg/2880px-GitHub_logo_2013.svg.png"
          },
          {
            name: "Google Docs",
            url: "https://docs.google.com/document/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Google_Docs_2020_Logo.svg/140px-Google_Docs_2020_Logo.svg.png"
          },
          {
            name: "Google Sheets",
            url: "https://docs.google.com/spreadsheets",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Google_Sheets_2020_Logo.svg/140px-Google_Sheets_2020_Logo.svg.png"
          },
          {
            name: "Unity Dashboard",
            url: "https://dashboard.unity3d.com/organizations/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c4/Unity_2021.svg/348px-Unity_2021.svg.png",
          },
          {
            name: "Google Cloud Storage",
            url: "https://console.cloud.google.com/storage/browser?project=opengrid-retail&prefix=&forceOnObjectsSortingFiltering=true",
            image: "https://upload.wikimedia.org/wikipedia/en/thumb/5/51/Google_Cloud_logo.svg/440px-Google_Cloud_logo.svg.png",
          },
          {
            name: "Google Domains",
            url: "https://domains.google.com/registrar/opengrid.xyz",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d2/Google_Domains_beta_logo.svg/440px-Google_Domains_beta_logo.svg.png",
          },
          {
            name: "Firebase Console - Retail",
            url: "https://console.firebase.google.com/project/opengrid-retail/overview",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/37/Firebase_Logo.svg/440px-Firebase_Logo.svg.png",
          },
          {
            name: "Rowy Dashboard",
            url: "https://opengrid-retail.rowy.app/",
            image: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/n8w166ytkeulasz5zvyq",
          },
          {
            name: "Retail Dashboard",
            url: "https://opengrid-dashboard.web.app",
            image: "https://res.cloudinary.com/dq5lw8oy1/image/upload/v1629990704/OpenGridLandingPage/OG_Logo_Black_Transparency_2x_wuj25t.png",
          },
          {
            name: "Block Demo",
            url: "https://block-dev.opengrid.xyz",
            image: "https://res.cloudinary.com/dq5lw8oy1/image/upload/v1629990704/OpenGridLandingPage/OG_Logo_Black_Transparency_2x_wuj25t.png",
          },
          {
            name: "Block Github Issue",
            url: "https://github.com/Open-Grid/ogUnity-template-block/issues/new",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/GitHub_logo_2013.svg/2880px-GitHub_logo_2013.svg.png"
          },
          {
            name: "Store Demo",
            url: "https://store-dev.opengrid.xyz",
            image: "https://res.cloudinary.com/dq5lw8oy1/image/upload/v1629990704/OpenGridLandingPage/OG_Logo_Black_Transparency_2x_wuj25t.png",
          },
          {
            name: "Store Github Issue",
            url: "https://github.com/Open-Grid/ogUnity-salty-retail/issues/new",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/GitHub_logo_2013.svg/2880px-GitHub_logo_2013.svg.png"
          },
        ]}
      />
    </>
  );
}

export default requireAuth(IndexPage);
